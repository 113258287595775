import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles.css";
import logo from "../assets/logo.png";

const LANDING_PAGE_INCREMENT_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds
const INITIAL_COUNT = 541;

const LandingPage = () => {
  const [rugPullsDetected, setRugPullsDetected] = useState(() => {
    const savedCount = localStorage.getItem("rugPullsDetected");
    return savedCount ? parseInt(savedCount, 10) : INITIAL_COUNT;
  });

  useEffect(() => {
    const lastIncrementTime = localStorage.getItem("lastIncrementTime");

    if (lastIncrementTime) {
      const now = Date.now();
      const timeElapsed = now - parseInt(lastIncrementTime, 10);
      const incrementsToAdd = Math.floor(
        timeElapsed / LANDING_PAGE_INCREMENT_INTERVAL
      );
      if (incrementsToAdd > 0) {
        const newCount = rugPullsDetected + incrementsToAdd;
        setRugPullsDetected(newCount);
        localStorage.setItem("rugPullsDetected", newCount);
      }
    }

    const incrementCount = () => {
      const newCount = rugPullsDetected + 1;
      setRugPullsDetected(newCount);
      localStorage.setItem("rugPullsDetected", newCount);
      localStorage.setItem("lastIncrementTime", Date.now());
    };

    const intervalId = setInterval(
      incrementCount,
      LANDING_PAGE_INCREMENT_INTERVAL
    );
    localStorage.setItem("lastIncrementTime", Date.now());

    return () => clearInterval(intervalId);
  }, [rugPullsDetected]);

  return (
    <div className="landing-page min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-900 via-black to-gray-800 text-white">
      <nav className="navbar flex justify-between w-full p-6">
        {" "}
        <img src={logo} alt="Logo" className="w-40 h-auto" />
        <div className="flex space-x-8">
          <Link
            to="/"
            className="nav-link text-xl font-bold text-teal-300 hover:text-teal-400 transition-colors duration-300 transform hover:scale-110"
          >
            {" "}
            Home
          </Link>
        </div>
      </nav>

      <div className="mt-4 text-center px-4">
        <p className="text-4xl font-bold">
          {" "}
          Welcome to <span className="text-teal-400">RugChek.fun</span>! Your
          trusted partner in identifying rug pulls.
        </p>
        <p className="mt-2 text-lg opacity-75">
          {" "}
          Stay safe, stay informed. We're constantly watching to keep you ahead
          of the game.
        </p>
      </div>

      <div className="mt-10 flex flex-col md:flex-row items-center md:items-start justify-center md:space-x-8 space-y-8 md:space-y-0 w-full px-6 pb-16">
        {" "}
        
        <div className="content flex flex-col items-center md:items-start text-center md:text-left w-full md:w-1/2">
          <h1 className="text-4xl font-bold tracking-widest glow-text">
            Rugpulls Detected
          </h1>
          <div className="detected-bar mt-5 text-5xl font-extrabold neon-text">
            {rugPullsDetected}
          </div>
          <p className="mt-2 text-xl opacity-75">
            Successfully Detected and Counting...
          </p>
          <div className="mt-10 flex space-x-6">
            <Link to="/pumpfun">
              <button className="main-button hover:text-black px-6 py-3 bg-teal-500 text-lg font-semibold rounded-lg hover:bg-teal-400 transition-transform duration-300 transform hover:scale-105 neon-button">
                PUMPFUN
              </button>
            </Link>
            <button className="main-button hover:text-black  px-6 py-3 bg-gray-700 text-lg font-semibold rounded-lg cursor-not-allowed opacity-50">
              MOONSHOT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
