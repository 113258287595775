import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import * as buffer from "buffer";

// Set up Buffer for use in the browser environment
window.Buffer = buffer.Buffer;

// Replace the network connection with your Helius RPC URL, devnet (https://api.devnet.solana.com) , mainnet(https://mainnet.helius-rpc.com/?api-key=6f1572e6-c351-4f6b-96b3-dc03d5bcc15b")
const heliusRpcUrl = "https://mainnet.helius-rpc.com/?api-key=6f1572e6-c351-4f6b-96b3-dc03d5bcc15b";

// Create a new connection using the Helius RPC URL
const connection = new Connection(heliusRpcUrl, "confirmed");

// Function to transfer SOL from one account to another
const transfer = async (provider, publicKey, destPubKey) => {
  console.log("Public Key:", publicKey.toString());
  console.log("Destination Public Key:", destPubKey);

  // Get the initial balance of the sender's account
  const initialBalance = await getSol(publicKey);
  console.log("Initial Balance: ", initialBalance);

  console.log("Initializing the transaction...");

  // Create a new transaction object
  let transaction = new Transaction({
    feePayer: publicKey,
    recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
  });

  console.log("Adding transfer instruction to the transaction...");

  // Update the amount to 0.02 SOL
  const amount = 0.0002 * LAMPORTS_PER_SOL; // Amount to transfer in lamports

  // Add transfer instruction to the transaction
  transaction.add(
    SystemProgram.transfer({
      fromPubkey: publicKey,
      toPubkey: new PublicKey(destPubKey),
      lamports: amount,
    })
  );

  console.log("Signing the transaction...");

  // Sign the transaction with the provider (wallet)
  try {
    transaction = await provider.signTransaction(transaction);
  } catch (err) {
    console.error("Error signing transaction:", err);
    return { error: "Transaction was rejected" };
  }

  console.log("Sending the transaction to the network...");

  // Send the signed transaction to the network
  try {
    const id = await connection.sendRawTransaction(transaction.serialize(), {
      skipPreflight: false,
    });
    console.log(`Transaction ID: ${id}`);

    // Confirm the transaction
    const confirmation = await connection.confirmTransaction(id);
    console.log(`Transaction confirmed in slot: ${confirmation.context.slot}`);
  } catch (e) {
    console.error("Error sending or confirming transaction:", e);
    return { error: "Transaction failed" };
  }

  // Get the final balance of the sender's account
  const myBalance = await getSol(publicKey);
  return `Your Balance: ${myBalance} SOL`;
};

// Function to get the SOL balance of a given public key
const getSol = async (key) => {
  try {
    const balance = await connection.getBalance(key);
    return balance / LAMPORTS_PER_SOL; // Convert lamports to SOL
  } catch (e) {
    console.error("Error fetching balance:", e);
    return 0;
  }
};

export default transfer;
