import React from "react";
import "../styles.css";

const Moonshot = () => {
  return (
    <div className="moonshot-page">
      <div className="moonshot-container">
        <h1 className="moonshot-title">🌕 Moonshot</h1>
        <p className="coming-soon-text">🚀 We're Launching Soon...</p>
        <div className="countdown-timer">
          <p>Stay tuned for something exciting!</p>
        </div>
        <button className="notify-button">Notify Me</button>
        <div className="moon-animation">
          <img src="moon.png" alt="Moon" className="moon-img" />
          <img src="rocket.png" alt="Rocket" className="rocket-img" />
        </div>
      </div>
    </div>
  );
};

export default Moonshot;
