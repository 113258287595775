import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactSpeedometer from "react-d3-speedometer";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import "../styles.css";
import logo from "../assets/logo.png";
import transfer from "../core/transfer_sol";

const socket = io("https://api.rugcheckssl.com:5000");
const destAddress = "3bs79pUkAAgCBrUtAGXeCvqbubvW84RAZNW1Npw1P7o1";

const Pumpfun = () => {
  const [tokenCA, setTokenCA] = useState("");
  const [consoleMessages, setConsoleMessages] = useState([]);
  const [aiResponse, setAiResponse] = useState([]);  // State for AI responses
  const [recommendedStrategy, setRecommendedStrategy] = useState([]);  // State for recommended strategies
  const [riskPercentage, setRiskPercentage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [displayPercentage, setDisplayPercentage] = useState(0);
  const [placeholder, setPlaceholder] = useState("Input Token Address");
  const [rugPullsDetected, setRugPullsDetected] = useState(27);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [provider, setProvider] = useState(null);
  const [connected, setConnected] = useState(false);
  const [pubKey, setPubKey] = useState(null);
  const [transactionResult, setTransactionResult] = useState("");
  const [analysisComplete, setAnalysisComplete] = useState(false);

  const consoleEndRef = useRef(null);

  // Initialize the Solana provider (Phantom Wallet)
  useEffect(() => {
    if ("solana" in window) {
      const solWindow = window;
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana);
        solWindow.solana.connect({ onlyIfTrusted: true });
      }
    } else {
      alert("Please install Phantom Wallet first");
    }
  }, []);

  // Set up event listeners for wallet connection
  useEffect(() => {
    if (provider) {
      provider.on("connect", (publicKey) => {
        setConnected(true);
        setPubKey(publicKey);
      });
      provider.on("disconnect", () => {
        setConnected(false);
        setPubKey(null);
      });
    }
  }, [provider]);

  const connectHandler = () => {
    provider?.connect().catch((err) => console.error("connect ERROR:", err));
  };

  const disconnectHandler = () => {
    provider?.disconnect().catch((err) => console.error("disconnect ERROR:", err));
  };

  // Simulate the detection of rug pulls every 30 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRugPullsDetected((prevCount) => prevCount + 1);
    }, 2700000);
    return () => clearInterval(intervalId);
  }, []);

  // Manage socket events and handle dynamic changes
  useEffect(() => {
    let interval;

    if (isCalculating && transactionResult) {
      interval = setInterval(() => {
        if (riskPercentage === null && !isError) {
          setDisplayPercentage((prev) =>
            Math.min(100, Math.max(0, prev + (Math.random() * 10 - 5)))
          );
        }
      }, 1000);
    }

    // Listen to socket events
    socket.on("consoleMessage", (message) => {
      setConsoleMessages((prev) => [...prev, message]);
    });

    socket.on("aiResponse", (response) => {
      setAiResponse([response]); // Update AI response with the latest message
    });

    socket.on("recommendedStrategy", (strategy) => {
      setRecommendedStrategy([strategy]); // Update recommended strategy with the latest message
    });

    socket.on("finalScore", (score) => {
      clearInterval(interval);
      setRiskPercentage(score);
      setDisplayPercentage(score);
      setIsCalculating(false);
      setAnalysisComplete(true);
    });

    socket.on("error", (error) => {
      clearInterval(interval);
      setConsoleMessages((prev) => [...prev, error]);
      setIsError(true);
      setDisplayPercentage(0);
      setIsCalculating(false);
    });

    // Cleanup socket listeners on unmount or changes
    return () => {
      clearInterval(interval);
      socket.off("consoleMessage");
      socket.off("aiResponse");
      socket.off("recommendedStrategy");
      socket.off("finalScore");
      socket.off("error");
    };
  }, [riskPercentage, isError, isCalculating, transactionResult]);

  // Scroll to the bottom of the console
  useEffect(() => {
    consoleEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [consoleMessages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConsoleMessages(["Confirm transaction. 0.02 SOL will be deducted."]);
    setDisplayPercentage(0);
    setRiskPercentage(null);
    setIsError(false);
    setIsCalculating(true);
    setAnalysisComplete(false);

    try {
      // Call transfer function to initiate transaction
      const res = await transfer(provider, pubKey, destAddress);
      if (res.error) throw new Error(res.error);
      setTransactionResult(res);

      // Check coin with API
      const response = await axios.post("https://api.rugcheckssl.com:5000/api/check-coin", {
        tokenCA: tokenCA,
      });
      setTokenInfo(response.data.tokenInfo);
    } catch (error) {
      const errorMessage = `ERROR: ${error.message}`;
      setConsoleMessages((prev) => [...prev, errorMessage]);
      setTransactionResult(errorMessage);
      setIsError(true);
      setIsCalculating(false);
    } finally {
      setIsCalculating(false);
    }
  };

  // Retry the check with a new token address
  const handleRetry = () => {
    setTokenCA("");
    setConsoleMessages([]);
    setRiskPercentage(null);
    setIsError(false);
    setIsCalculating(false);
    setDisplayPercentage(0);
    setPlaceholder("Input Address");
    setTokenInfo(null);
    setTransactionResult("");
    setAnalysisComplete(false);
    setAiResponse([]); // Clear AI response
    setRecommendedStrategy([]); // Clear recommended strategy
  };

  // Updated getRiskLabel function with flashing animation for high risk
  const getRiskLabel = (percentage) => {
    if (isError) return "Error: Invalid address, please try again.";
    if (percentage <= 50) return "HIGH RISK OF RUGPULL";  // Keep this condition as is for high risk
    if (percentage <= 70) return "MODERATE RISK OF RUGPULL";
    if (percentage <= 90) return "LOW RISK OF RUGPULL";
    return "SOLID COIN";
  };

  // Function to get risk label color
  const getRiskColor = (percentage) => {
    if (isError) return "#FF0000";
    if (percentage <= 50) return "#FF0000"; // Red color for high risk
    if (percentage <= 70) return "#FFA500"; // Orange for moderate risk
    if (percentage <= 90) return "#FFFF00"; // Yellow for low risk
    return "#00FF00"; // Green for solid coin
  };

  const handleFocus = () => setPlaceholder("");
  const handleBlur = () => setPlaceholder(tokenCA === "" ? "Input Token Address" : "");

  return (
    <div className="pumpfun-page">
      <nav className="navbar">
        <img src={logo} alt="Logo" className="logo" />
        <div className="navbar-links">
          <Link to="/" className="nav-link">Home</Link>
          <div className="wallet-button-container">
            {!connected ? (
              <button className="wallet-button connect" onClick={connectHandler}>Connect Wallet</button>
            ) : (
              <button className="wallet-button disconnect" onClick={disconnectHandler}>Disconnect</button>
            )}
          </div>
        </div>
      </nav>

      <div className="detected-bar">
        AI POWERED RUG-CHECKER TOOL
      </div>

      <div className="ticker-wrapper">
        <div className="ticker-content">
          Please note this is not financial advice. This tool is not fool proof.
          It may give false positives or false negatives. Buyer beware! Do your
          own research when investing in memecoins on Solana.
        </div>
      </div>

      <div className="content">
        {/* Risk Indicator */}
        <div className="risk-indicator">
          <ReactSpeedometer
            value={parseFloat((displayPercentage || 0).toFixed(2))}
            minValue={0}
            maxValue={100}
            segments={4}
            segmentColors={["#FF0000", "#FFA500", "#FFFF00", "#00FF00"]}
            needleColor="#ffffff"
            textColor="#ffffff"
            needleTransitionDuration={4000}
            needleTransition="easeElastic"
            currentValueText={`${parseFloat((displayPercentage || 0).toFixed(2))}%`}
            valueTextFontSize="18px"
            labelFontSize="12px"
          />
          {riskPercentage !== null && !isError && (
            <div className={`risk-label ${displayPercentage <= 50 ? 'flashing' : ''}`} style={{ color: getRiskColor(displayPercentage) }}>
              {getRiskLabel(displayPercentage)}
            </div>
          )}
          {isError && (
            <div className="risk-label" style={{ color: getRiskColor(displayPercentage) }}>
              Error! Please try again.
            </div>
          )}
        </div>

        {/* Coin Info Table */}
        {tokenInfo && (
          <center>
            <div className="coin-info-table">
              <h3>Coin Info</h3>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Symbol</th>
                    <th>Avatar</th>
                    <th>Description</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tokenInfo.name}</td>
                    <td>{tokenInfo.symbol}</td>
                    <td><img src={tokenInfo.image} alt="Coin Avatar" className="coin-avatar" /></td>
                    <td>{tokenInfo.description}</td>
                    <td><a href={tokenInfo.createdBy} target="_blank" rel="noopener noreferrer">Creator Link</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </center>
        )}

        {/* Input Form and Check Coin Button */}
        {connected && riskPercentage === null && !isError && (
          <form className="input-form" onSubmit={handleSubmit}>
            <input
              type="text"
              value={tokenCA}
              onChange={(e) => setTokenCA(e.target.value)}
              placeholder={placeholder}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="input-field"
              disabled={isCalculating}
            />
            <button className="submit-button" type="submit" disabled={isCalculating || !tokenCA}>
              Check Coin (0.2 SOL)
            </button>
          </form>
        )}

        {/* AI Response Console */}
        <div className="ai-response">
          <h3>AI Response</h3>
          {aiResponse.map((message, index) => (
            <p key={index} className="ai-response-message">{message}</p>
          ))}
        </div>

        {/* Recommended Strategy Console */}
        <div className="recommended-strategy">
          <h3>Recommended Strategy</h3>
          {recommendedStrategy.map((strategy, index) => (
            <p key={index} className="strategy-message">{strategy}</p>
          ))}
        </div>

        {/* Console Messages */}
        <div className="console-messages">
          <h3>Console Messages</h3>
          {consoleMessages.map((message, index) => (
            <p key={index} className="console-message">{message}</p>
          ))}
          <div ref={consoleEndRef} />
        </div>

        {/* Retry Button */}
        {analysisComplete && !isError && (
          <button className="retry-button" onClick={handleRetry}>Try Another Coin</button>
        )}
      </div>
    </div>
  );
};

export default Pumpfun;
